
import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const UltimateMaga = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="A Glimpse Into the Other Side" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel='stylesheet' href='../styles/global.css' />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>A Glimpse Into the Other Side</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. May 10, 2021) </p>
        </div>
        <div className="articleBody">
          <p>I never intended to publish this, but demons don't die in the dark.</p>
          <p>Back in November, I sent a <s>henchman</s> reporter down to cover the Million MAGA March in Washington D.C. (media magnaaate haha I'm a media magnate :* &#60;3). </p>
          <p>That reporter, who has requested to remain anonymous for reasons that may become clear, accidentally conducted an interview with an extremely powerful individual, a key architect of the American right-wing militia machine.</p>
          <p>The reporter approached this legendary man -- who was wearing a red flannel shirt, his ass crack mostly covered by khaki cargos -- entirely at random.</p>
          <p>Below is their conversation, unedited.</p>
          <p>
          <span className="editorsNote">Excuse me, sir, can I ask you a few questions about the demonstration?</span>

Shoot. 

<span className="editorsNote">So, which MAGA are you?</span>

The millionth one. 

<span className="editorsNote">Ah, I see. And how many of y'all are there?</span>

A million.

<span className="editorsNote">Oh, wow. So you're like, the Ultimate MAGA?</span>

That's my official title, yes.

<span className="editorsNote">Okay.</span>

Okay.

<span className="editorsNote">Who ranks higher though, you or Q?</span>
          </p>
        <p>
          By the reporter's account, that question caused the man to scoff, roll his eyes, and detonate an impact smoke bomb. From the smoke, per that reporter's account, a taller body double emerged dressed in exactly the same clothing as the Ultimate MAGA.
        </p>
        <p>
          Naw but I still really can't believe they lost that union drive though that broke my heart.
        </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>

  );
};
export default UltimateMaga;